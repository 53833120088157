import React, { useContext } from 'react';
import styled from 'styled-components';

import Context from '../config/Context';
import ContactForm from '../components/contact/ContactForm';
import Success from '../components/contact/Success';
import { SubtitleContainer } from '../styles/Components';

const ContactContainer = styled.div``;

const Contact = () => {
  const context = useContext(Context);
  const { formMounted, successMounted, titleHeight } = context;
  return (
    <ContactContainer className="switch-page">
      <SubtitleContainer height={titleHeight}>
        {formMounted && <ContactForm />}
        {successMounted && <Success />}
      </SubtitleContainer>
    </ContactContainer>
  );
};

export default Contact;
