import styled from 'styled-components';

import variables from './Variables';
import { mediaMax, mediaMin } from './MediaQueries';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${variables.colors.white};
  z-index: 100;
  padding: 32px 32px 0 32px;
  ${mediaMin.tabletLandscape`
    position: fixed;
    padding: 64px 64px 32px 64px;
    background-color: ${variables.colors.darkGreen};
    box-shadow: 0px 5px 15px 0px #052421;

  `}

  img {
    height: 19px;
    margin-right: auto;
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }
  .subtitle {
    font-style: italic;
    color: ${variables.colors.lightGreen};
    margin-top: 16px;
  }
`;

const SubtitleContainer = styled.div`
  z-index: 101;
  padding: 0 32px 32px 32px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${props => props.height}px);
  justify-content: space-between;
  ${mediaMin.tabletLandscape`
    min-height: 100vh;
    position: initial !important;
    padding: 64px;
    justify-content: space-between;
  `}
`;

export { TitleContainer, SubtitleContainer };
