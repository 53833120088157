import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Context from '../../config/Context';
import SocialMedia from './SocialMedia';
import { mediaMin } from '../../styles/MediaQueries';
import variables from '../../styles/Variables';

const SuccessContainer = styled.div`
  min-height: calc(100vh - ${props => props.height}px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  width: ${props => props.width}px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  padding-top: 24px;
  ${mediaMin.tabletLandscape`
    min-height: initial;
    height: 100%;
    padding-top: 96px;
  `}
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${variables.colors.lightGreen};
  font-size: 24px;
  a {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    margin-top: 24px;
    &:visited {
      color: ${variables.colors.lightGreen};
    }
    &:hover {
      text-decoration: none;
      color: ${variables.colors.offWhite};
    }
  }
`;

const Success = () => {
  const context = useContext(Context);
  const { logoWidth, successVisible, titleHeight } = context;
  return (
    <SuccessContainer
      visible={successVisible}
      width={logoWidth}
      height={titleHeight}
    >
      <SuccessMessage>
        Thank you! Someone from our team will be contacting you soon.
        <Link to="/">Return</Link>
      </SuccessMessage>
      <SocialMedia />
    </SuccessContainer>
  );
};

export default Success;
