import React from 'react';
import styled from 'styled-components';

import { mediaMin } from '../../styles/MediaQueries';
import variables from '../../styles/Variables';

const SocialMediaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 64px 0 0 0;
  ${mediaMin.tabletLandscape`
    margin: 64px 0 0 0;
  `} .logo {
    height: 24px;
    width: 24px;
  }
  a {
    color: ${variables.colors.offWhite};
    font-family: 'Noto Sans', sans-serif;
    margin-top: 8px;
    &:hover {
      text-decoration: none;
    }
    &:visited {
      color: ${variables.colors.offWhite};
    }
  }
  .big-margin {
    margin-top: 24px;
  }
`;

const SocialMedia = () => {
  return (
    <SocialMediaContainer>
      <a
        href="https://www.instagram.com/lily_kwong/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="logo"
          src="/images/contact/instagram.svg"
          alt="instagram logo"
        />
      </a>
      <a
        href="https://www.instagram.com/studiolilykwong/"
        target="_blank"
        rel="noopener noreferrer"
      >
        @STUDIOLILYKWONG
      </a>
      <a
        href="https://www.instagram.com/lily_kwong/"
        target="_blank"
        rel="noopener noreferrer"
      >
        @LILY_KWONG
      </a>
      <a
        className="big-margin"
        href="#"
        rel="noopener noreferrer"
      >
        New York | Los Angeles
      </a>
    </SocialMediaContainer>
  );
};

export default SocialMedia;
