import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Logo from '../components/home/logo';

const HomeContainer = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    text-decoration: none;
  }
`;

const Home = () => {
  return (
    <HomeContainer className="switch-page" to="/contact">
      <Logo />
    </HomeContainer>
  );
};

export default Home;
