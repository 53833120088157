/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { mediaMin } from '../../styles/MediaQueries';
import ResponsiveImage from '../utils/responsive-image';

const MaskContainer = styled.div`
  z-index: 1;
  background-color: white;
  mask: url('/images/logo/logo.svg');
  mask-repeat: no-repeat;
  mask-position: right;
  min-width: 110%;
  height: 80%;
  transform: translate(-5%, 0);
  opacity: ${props => (props.isLoaded ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
  ${mediaMin.tabletLandscape`
    position: absolute;
    min-height: 110%;
    min-width: 110%;
    transform: translate(-5%, -5%);
  `}
  .z-1 {
    z-index: 1;
  }
  .z-2 {
    z-index: 2;
  }
  .z-3 {
    z-index: 3;
  }
  .z-4 {
    z-index: 4;
  }
  .z-5 {
    z-index: 5;
  }
  .z-6 {
    z-index: 6;
  }
`;

const OpacityController = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${props => (props.backgroundActive ? '1' : '0')};
  transition: opacity 5s ease-in-out;
  .bg-img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
`;

const Logo = () => {
  const [activeBackground, setActiveBackground] = useState(0);
  const [loaded, setLoaded] = useState([]);

  useEffect(() => {
    const backgroundInterval = setInterval(handleBackground, 6000);
    return () => {
      clearInterval(backgroundInterval);
    };
  }, []);

  const handleBackground = () => {
    console.log('bg');
    setActiveBackground(prevBG => {
      return (prevBG + 1) % 6;
    });
  };

  const handleLoaded = index => {
    const newLoaded = loaded.concat([index]);
    setLoaded(newLoaded);
  };

  return (
    <MaskContainer isLoaded={loaded.length >= 6}>
      <OpacityController
        className="z-6"
        backgroundActive={activeBackground >= 5}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(6);
          }}
          srcPath="/images/background/bg_6"
          imgAlt="green plants"
          imgClass="bg-img bg-6"
        />
      </OpacityController>
      <OpacityController
        className="z-5"
        backgroundActive={activeBackground >= 4}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(5);
          }}
          srcPath="/images/background/bg_5"
          imgAlt="green plants"
          imgClass="bg-img bg-5"
        />
      </OpacityController>
      <OpacityController
        className="z-4"
        backgroundActive={activeBackground >= 3}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(4);
          }}
          srcPath="/images/background/bg_4"
          imgAlt="green plants"
          imgClass="bg-img bg-4"
        />
      </OpacityController>
      <OpacityController
        className="z-3"
        backgroundActive={activeBackground >= 2}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(3);
          }}
          srcPath="/images/background/bg_3"
          imgAlt="green plants"
          imgClass="bg-img bg-3"
        />
      </OpacityController>
      <OpacityController
        className="z-2"
        backgroundActive={activeBackground >= 1}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(2);
          }}
          srcPath="/images/background/bg_2"
          imgAlt="green plants"
          imgClass="bg-img bg-2"
        />
      </OpacityController>
      <OpacityController
        className="z-1"
        backgroundActive={activeBackground >= 0}
      >
        <ResponsiveImage
          onLoadProp={() => {
            handleLoaded(1);
          }}
          srcPath="/images/background/bg_1"
          imgAlt="green plants"
          imgClass="bg-img bg-1"
        />
      </OpacityController>
    </MaskContainer>
  );
};

export default Logo;
