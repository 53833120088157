/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useContext } from 'react';

import Context from '../config/Context';
import { TitleContainer } from '../styles/Components';

const Title = () => {
  const TitleRef = useRef(null);
  const LogoRef = useRef(null);
  const context = useContext(Context);
  const { getLogoWidth, getTitleHeight } = context;

  useEffect(() => {
    getLogoWidth(LogoRef);
    getTitleHeight(TitleRef);
    window.addEventListener('resize', () => {
      getLogoWidth(LogoRef);
      getTitleHeight(TitleRef);
    });
    return () => {
      window.removeEventListener('resize', () => {
        getLogoWidth(LogoRef);
        getTitleHeight(TitleRef);
      });
    };
  }, []);

  return (
    <TitleContainer ref={TitleRef}>
      <img
        src="/images/logo/LSK_wordmark.svg"
        alt="studio lily kwong"
        ref={LogoRef}
      />
      <span className="subtitle">Reconnecting People to Nature</span>
    </TitleContainer>
  );
};

export default Title;
