/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import styled from 'styled-components';

import Context from '../../config/Context';
import SocialMedia from './SocialMedia';
import variables from '../../styles/Variables';
import { mediaMin } from '../../styles/MediaQueries';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  width: ${props => props.width}px;
  opacity: ${props => (props.width && props.visible ? '1' : '0')};
  visibility: ${props => (props.width && props.visible ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease, visibility 0.5s ease;
`;

const Form = styled.form`
  color: ${variables.colors.lightGreen};

  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
  ${mediaMin.tabletLandscape`
    margin-top: 96px;
  `}
  button {
    background: transparent;
    border: none;
    color: inherit;
    font-size: 24px;
    padding: 0;
    cursor: pointer;
    transition: color 0.1s ease;
    &:hover {
      color: ${variables.colors.offWhite};
    }
  }
`;

const TextInput = styled.input`
  font-size: inherit;
  font-style: inherit;
  font-family: 'Noto Sans', sans-serif;
  color: ${variables.colors.offWhite};
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid
    ${props =>
      props.error ? variables.colors.error : variables.colors.lightGreen};
  padding: 8px 0;
  margin: 0 0 16px 0;
  &::placeholder {
    color: ${variables.colors.lightGreen};
    font-style: italic;
  }
`;

const TextArea = styled.textarea`
  font-size: inherit;
  font-style: inherit;
  font-family: 'Noto Sans', sans-serif;
  color: ${variables.colors.offWhite};
  width: 100%;
  height: 128px;
  background-color: transparent;
  border: 1px solid ${variables.colors.lightGreen};
  padding: 8px;
  margin: 8px 0 16px 0;
  resize: none;
  &::placeholder {
    color: ${variables.colors.lightGreen};
    font-style: italic;
  }
`;

const Select = styled.select`
  font-size: inherit;
  width: 100%;
  margin: 0 0 16px 0;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid
    ${props =>
      props.error ? variables.colors.error : variables.colors.lightGreen};
  border-radius: 0;
  background: url('/images/contact/Triangle.svg') no-repeat right transparent;
  background-size: 16px;
  background-position-x: ${props => props.logoWidth - 24}px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: ${props =>
    props.isPlaceholder
      ? variables.colors.lightGreen
      : variables.colors.offWhite};
  font-style: ${props => (props.isPlaceholder ? 'italic' : '')};
`;

const ContactForm = () => {
  const context = useContext(Context);
  const {
    logoWidth,
    formData,
    formErrors,
    handleChange,
    handleSubmit,
    formVisible
  } = context;

  return (
    <FormContainer width={logoWidth} visible={formVisible}>
      <Form onSubmit={handleSubmit}>
        <TextInput
          name="name"
          placeholder="Name*"
          onChange={handleChange}
          value={formData.name}
          type="text"
          error={formErrors.name}
        />
        <TextInput
          name="companyName"
          placeholder="Company Name"
          onChange={handleChange}
          value={formData.companyName}
          type="text"
        />
        <TextInput
          name="email"
          placeholder="Email*"
          onChange={handleChange}
          value={formData.email}
          type="email"
          error={formErrors.email}
        />
        <TextInput
          name="phone"
          placeholder="Telephone*"
          onChange={handleChange}
          value={formData.phone}
          type="text"
          error={formErrors.phone}
        />
        <Select
          name="interest"
          onChange={handleChange}
          logoWidth={logoWidth}
          isPlaceholder={!formData.interest}
          error={formErrors.interest}
        >
          <option disabled selected hidden>
            Interest*
          </option>
          <option value="Press">Press</option>
          <option value="Project">Project</option>
          <option value="Other">Other</option>
        </Select>
        <TextArea
          name="message"
          placeholder="Message"
          onChange={handleChange}
          value={formData.message}
          type="textarea"
        />
        <button type="submit">Submit</button>
      </Form>
      <SocialMedia />
    </FormContainer>
  );
};

export default ContactForm;
