const variables = {
  colors: {
    darkGreen: '#052421',
    lightGreen: '#3B694D',
    white: '#F4F2EC',
    offWhite: '#BFC7C6',
    error: '#610000'
  }
};

export default variables;
