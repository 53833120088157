/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Context from '../config/Context';

const ContextProvider = props => {
  const [logoWidth, setLogoWidth] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [formMounted, setFormMounted] = useState(true);
  const [formVisible, setFormVisible] = useState(true);
  const [successMounted, setSuccessMounted] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    interest: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    interest: false
  });

  const getLogoWidth = ref => {
    if (ref.current && logoWidth !== ref.current.width) {
      setLogoWidth(ref.current.width);
    } else {
      const checkAgain = setInterval(() => {
        if (ref.current && logoWidth !== ref.current.width) {
          setLogoWidth(ref.current.width);
          clearInterval(checkAgain);
        }
      }, 100);
    }
  };

  const getTitleHeight = ref => {
    if (ref.current && logoWidth !== ref.current.clientHeight) {
      setTitleHeight(ref.current.clientHeight);
    } else {
      const checkAgain = setInterval(() => {
        if (ref.current && logoWidth !== ref.current.clientHeight) {
          setTitleHeight(ref.current.clientHeight);
          clearInterval(checkAgain);
        }
      }, 100);
    }
  };

  const handleChange = e => {
    e.persist();
    setFormData(previousValues => ({
      ...previousValues,
      [e.target.name]: e.target.value
    }));
  };

  const showSuccess = () => {
    setFormVisible(false);
    setTimeout(() => {
      setFormMounted(false);
      setSuccessMounted(true);
    }, 500);
    setTimeout(() => {
      setSuccessVisible(true);
    }, 750);
  };

  const checkForErrors = () => {
    const newErrors = {
      name: false,
      email: false,
      phone: false,
      interest: false
    };

    if (!formData.name) newErrors.name = true;
    if (!formData.email) newErrors.email = true;
    if (!formData.phone) newErrors.phone = true;
    if (!formData.interest) newErrors.interest = true;

    setFormErrors(newErrors);

    return Object.values(newErrors).every(val => !val);
  };

  const sendEmail = () => {
    const emailData = {
      from: '"SLK Teaser Site" <no_reply_studiolilykwong@dbox.com>',
      to: `"Lily Kwong" <lsk@studiolilykwong.com>, assistant@studiolilykwong.com`,
      subject: `New Inquiry - ${formData.interest}`,
      text: `
        Name: ${formData.name}\n
        ${formData.companyName && `Company Name: ${formData.companyName}\n`}
        Email: ${formData.email}\n
        Phone: ${formData.phone}\n
        ${formData.message && `Message: ${formData.message}`}
      `
    };

    fetch('https://form.api.dbxd.com/post-ses-email', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailData)
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (checkForErrors()) {
      sendEmail();
      showSuccess();
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        logoWidth,
        getLogoWidth,
        formData,
        setFormData,
        handleChange,
        handleSubmit,
        formMounted,
        formVisible,
        successMounted,
        successVisible,
        formErrors,
        titleHeight,
        getTitleHeight
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default ContextProvider;
