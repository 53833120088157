import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import styled from 'styled-components';

import ContextProvider from './provider/ContextProvider';
import {
  AccessibilityStyles,
  GlobalStyles,
  TypographyStyles
} from './styles/global/_module';
import { Home, Contact } from './pages/_module';
import Title from './components/Title';
import variables from './styles/Variables';
import { mediaMin } from './styles/MediaQueries';

const AppContainer = styled.div`
  background-color: ${variables.colors.darkGreen};
  overflow: auto;
  ${mediaMin.tabletLandscape`
    overflow: auto;
  `}
  .switch-wrapper {
    position: relative;
    height: 100%;
    transition: opacity 1s ease;
    .switch-page {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`;

const App = () => {
  return (
    <AppContainer className="App">
      <ContextProvider>
        <AccessibilityStyles />
        <GlobalStyles />
        <TypographyStyles />
        <Title />
        <Router>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route exact path="/" component={Home} />
            <Route path="/contact" component={Contact} />
          </AnimatedSwitch>
        </Router>
      </ContextProvider>
    </AppContainer>
  );
};

export default App;
